import { getClientAreaMedica } from './http'

class ClienteService {

  constructor(path) {
    this.path = path
  }

  findAll({ page = 1, limit = 10, filter = '' }) {
    return getClientAreaMedica().get(`${this.path}?page=${page}&limit=${limit}&filter=${filter}`)
  }

  findById(id) {
    return getClientAreaMedica().get(`${this.path}/${id}`)
  }

  save(record) {
    if (record.id) {
      return getClientAreaMedica().put(`${this.path}/${record.id}`, record)
    }
    return getClientAreaMedica().post(this.path, record)
  }

  remove(id) {
    return getClientAreaMedica().delete(`${this.path}/${id}`)
  }
}

export default ClienteService
